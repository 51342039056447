<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-dialog
        v-model="showDetail"
        fullscreen
        persistent
        transition="dialog-top-transition"
        @keydown.esc="close"
      >
        <v-card>
          <v-app-bar height="100">
            <v-btn
              color="indigo"
              dark
              tile
              @click="save"
              v-if="fiche.statut_id == '18'"
              class="mx-2"
              :disabled="change == 1"
            >
              Enregistrer
            </v-btn>

            <v-divider vertical class="mx-3"></v-divider>
            <v-btn
              color="indigo"
              dark
              tile
              @click="valider"
              v-if="fiche.statut_id == '18' && fiche.id > 0"
              :disabled="output_list.length == 0 && arret_list.length == 0"
            >
              Clotûrer
            </v-btn>
            <v-divider vertical class="mx-3"></v-divider>
            <v-toolbar-title>
              <h3>{{ formTitle }}</h3>
            </v-toolbar-title>
            <v-divider vertical class="mx-3"></v-divider>
            <v-select
              ref="agents"
              :items="agents"
              v-model="fiche.agent_id"
              item-text="nom"
              :item-value="'id'"
              label="Operateur"
              :readonly="readonly || ag_redonly || fiche.statut_id == '19'"
              :rules="[(v) => !!v || 'Operateur obligatoire']"
              dense
            >
            </v-select>

            <v-divider vertical class="mx-3"></v-divider>
            <span
              v-if="$store.state.auth.includes('04023') || $store.state.isadmin"
            >
              <datepicker
                label="Date Fiche"
                v-model="fiche.fiche_date"
                :edit="!readonly || fiche.statut_id == '18'"
                :rules="[(v) => !!v || 'Date obligatoire']"
                :clearable="false"
                :key="dp"
                @change="change++"
              ></datepicker>
            </span>
            <span v-else>
              <h3>{{ "Date : " + datefr(fiche.fiche_date) }}</h3>
            </span>

            <v-divider vertical class="mx-3"></v-divider>
            <v-select
              ref="shifts"
              :items="shifts"
              v-model="fiche.shift_id"
              item-text="label"
              item-value="id"
              label="Poste de Travail"
              :readonly="readonly || fiche.statut_id == '19'"
              :rules="[(v) => !!v || 'Poste de Travail obligatoire']"
              @change="change++"
            >
            </v-select>
            <v-divider vertical class="mx-3"></v-divider>

            <v-autocomplete
              v-model="fiche.machine_id"
              :item-text="'label'"
              :item-value="'machine_id'"
              :items="machines_list"
              label="Machine"
              :rules="[(v) => !!v || 'Machine obligatoire']"
              @change="machine_change"
              v-if="fiche.id < 0"
              dense
            ></v-autocomplete>
            <span v-else>
              <h3>{{ "Machine : " + machine.label }}</h3>
            </span>
            <v-divider vertical class="mx-3"></v-divider>

            <v-btn color="indigo" text @click.stop="close">
              <v-icon dark large> mdi-exit-to-app </v-icon></v-btn
            >
          </v-app-bar>
          <v-progress-linear
            v-if="progress"
            indeterminate
            :size="30"
            :width="7"
            color="purple"
          ></v-progress-linear>

          <!-- <v-card-title>
              {{ "Atelier : " + (bt.gamme_caption ? bt.gamme_caption : "") }}  
          </v-card-title> -->

          <v-card-text v-if="fiche.id > 0">
            <v-container fluid>
              <v-tabs v-model="tabs" icons-and-text centered>
                <v-tabs-slider></v-tabs-slider>
                <v-tab
                  key="0"
                  href="#0"
                  v-if="fiche.statut_id == '18'"
                  active-class="green-icon"
                >
                  Operations
                  <v-icon large>mdi-autorenew</v-icon></v-tab
                >

                <v-tab key="2" href="#2" active-class="orange-icon">
                  Arrêts
                  <v-icon large>mdi-alert-octagon-outline</v-icon></v-tab
                >
                <v-tab key="3" href="#3" active-class="red-icon">
                  Déchets
                  <v-icon large>mdi-close-octagon-outline</v-icon></v-tab
                >
                <v-tab key="1" href="#1" active-class="blue-icon">
                  Rapports
                  <v-icon large>mdi-file-document-check-outline</v-icon></v-tab
                >
              </v-tabs>
              <v-tabs-items v-model="tabs" class="pa-2">
                <v-tab-item :value="'0'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-alert
                        v-model="alert"
                        :type="type_alert"
                        dismissible
                        class="ma-2"
                        >{{ message }}</v-alert
                      >
                    </v-col>
                    <!--------- INTPUT -------->
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-card v-if="fiche.statut_id == '18'" elevation="6">
                            <v-card-title>
                              <v-row>
                                <v-col cols="12" sm="4" md="4">
                                  <v-select
                                    class="mt-4"
                                    ref="ofs"
                                    :items="orders"
                                    v-model="editedItem.of_id"
                                    item-text="no_order"
                                    :item-value="'id'"
                                    label="Ordre de transformation"
                                    :readonly="
                                      readonly ||
                                      ag_redonly ||
                                      fiche.statut_id == '19'
                                    "
                                    :rules="[(v) => !!v || 'Ordre obligatoire']"
                                    dense
                                    @change="orders_change"
                                    v-if="fiche.statut_id == '18'"
                                  >
                                  </v-select>
                                  <span v-else>
                                    OT : {{ fiche.no_order }}
                                  </span>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                  <v-switch
                                    color="indigo"
                                    :label="atelier ? 'Atelier' : 'Machine'"
                                    v-model="atelier"
                                    @change="refresh_product"
                                  >
                                  </v-switch>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                              <span>
                                <h2>
                                  Produit :
                                  {{ order.label }}
                                  / Code :
                                  {{ order.code }}
                                </h2></span
                              >
                              <!-- ITEM IN -->
                              <v-autocomplete
                                class="mt-3"
                                v-model="articles_in"
                                :item-value="'id'"
                                :filter="customFilter"
                                :items="product_items"
                                :label="
                                  (order.colis_unit
                                    ? order.colis_unit
                                    : 'Item') + ' en cours'
                                "
                                :rules="[
                                  (v) =>
                                    !!v ||
                                    (order.colis_unit
                                      ? order.colis_unit
                                      : 'Item') + ' obligatoire',
                                ]"
                                @change="current_change"
                                @update:search-input="onInputChange"
                                filled
                                outlined
                                multiple
                                :menu-props="{
                                  closeOnClick: true,
                                  closeOnContentClick: true,
                                }"
                                return-object
                              >
                                <template v-slot:selection="{ item }">
                                  <v-progress-linear
                                    :value="item.prc_rest"
                                    color="light-green darken-2"
                                    height="25"
                                    rounded
                                  >
                                    <template v-slot:default>
                                      {{
                                        item.code +
                                        " (" +
                                        (item.qte_rest +
                                          " " +
                                          item.unit +
                                          ")") +
                                        " (" +
                                        item.prc_rest +
                                        "%) " +
                                        item.tier_name
                                      }}
                                    </template>
                                  </v-progress-linear>
                                </template>
                                <template v-slot:append-outer>
                                  <v-btn
                                    icon
                                    @click="consomed"
                                    :disabled="articles_in.length != 1"
                                  >
                                    <v-icon large color="red" class="mb-3">
                                      mdi-close-circle-multiple
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <template slot="item" slot-scope="data">
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-html="data.item.code"
                                    ></v-list-item-title>
                                    <v-list-item-title
                                      v-html="data.item.article_label"
                                    ></v-list-item-title>
                                    <v-list-item-subtitle
                                      v-html="data.item.article_code"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                      v-html="data.item.ref"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                      v-html="data.item.tier_name"
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle
                                      v-html="
                                        data.item.qte_rest +
                                        ' ' +
                                        data.item.unit
                                      "
                                    ></v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                      <v-row>
                                        <v-col cols="12" sm="3" md="3">
                                          <v-progress-linear
                                            :value="data.item.prc_rest"
                                            color="light-green darken-2"
                                            height="25"
                                            rounded
                                          >
                                            <template v-slot:default>
                                              {{ data.item.prc_rest + "% " }}
                                            </template>
                                          </v-progress-linear>
                                        </v-col>
                                      </v-row>
                                    </v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <listitems
                            :list="output_list"
                            :headers="outputheaders"
                            :title="'Production'"
                            :qUpdate="qFichedetail"
                            :master="true"
                            :add="false"
                            :selitem="selitem"
                            :del="fiche.statut_id == '18'"
                            :del_disable="output.qte_cons > 0"
                            :Get_suf="'FicheDetail'"
                            :Update="fiche.statut_id == '18'"
                            :showedit="false"
                            :showstd="false"
                            :Total="true"
                            :print="false"
                            :exp_excel="false"
                            :key="frml"
                            @rowselect="OutputSelect"
                            @col_btn2_click="print_ticket"
                            :laoding="listload"
                            :ipg="15"
                            @delete="delete_item"
                          >
                          </listitems>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!--------- OUTPUT -------->
                    <v-col cols="12" sm="6" md="6">
                      <v-row>
                        <v-col cols="12" sm="12" md="12">
                          <v-card
                            v-if="fiche.statut_id == '18' && tabs == '0'"
                            elevation="6"
                          >
                            <v-card-title>
                              <v-row no-gutters>
                                <v-col cols="12" sm="2" md="2"> </v-col>
                                <v-col cols="12" sm="10" md="10">
                                  <v-sheet
                                    elevation="8"
                                    rounded="lg"
                                    color="grey darken-2"
                                  >
                                    <div class="pa-4">
                                      <v-chip-group
                                        active-class="primary--text"
                                        column
                                      >
                                        <v-chip
                                          v-for="(
                                            article, index
                                          ) in articles_sel"
                                          :key="index"
                                          :color="
                                            article.article_id ? 'green' : 'red'
                                          "
                                        >
                                          {{ article.caption }}
                                        </v-chip>
                                      </v-chip-group>
                                    </div>
                                  </v-sheet>
                                </v-col>
                              </v-row>
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text>
                              <v-row>
                                <v-col cols="12" sm="8" md="8"> </v-col>
                                <!-- Quantité commune -->
                                <v-col
                                  cols="12"
                                  sm="2"
                                  md="2"
                                  v-if="articles_sel.length > 1"
                                >
                                  <v-text-field
                                    autocomplete="off"
                                    prepend-inner-icon="mdi-arrow-split-horizontal"
                                    :ref="'qte'"
                                    dense
                                    v-model="editedItem.qte"
                                    :label="'Quantité'"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                    @input="qte_change"
                                    @keydown.enter="add_output"
                                    solo-inverted
                                  ></v-text-field>
                                </v-col>
                                <!-- Bouton ajouter -->
                                <v-col cols="12" sm="2" md="2">
                                  <v-btn
                                    ref="save"
                                    color="indigo"
                                    class="mx-1"
                                    dark
                                    @click="add_output"
                                    :disabled="
                                      articles_sel.length == 0 ||
                                      add_disable ||
                                      !editedItem.qte
                                    "
                                  >
                                    <v-icon large>mdi-arrow-down-box</v-icon>
                                    Ajouter
                                  </v-btn>
                                </v-col>
                              </v-row>
                              <!-- Lignes Items -->
                              <v-row
                                v-for="(article, index) in articles_sel.filter(
                                  (elm) => elm.article_id != null
                                )"
                                :key="index"
                                dense
                              >
                                <v-col cols="12" sm="3" md="2" class="mt-1">
                                  <v-chip>
                                    <v-avatar left class="green">
                                      {{ index + 1 }}
                                    </v-avatar>
                                    {{ article.caption }}
                                  </v-chip>
                                </v-col>
                                <v-col
                                  cols="12"
                                  sm="3"
                                  md="3"
                                  v-if="code_produit == '1'"
                                >
                                  <v-text-field
                                    autocomplete="off"
                                    :ref="'ref' + index"
                                    dense
                                    v-model="article.ref"
                                    :label="'Code'"
                                    :rules="[(v) => !!v || 'Code obligatoire']"
                                    :readonly="readonly"
                                    hide-spin-buttons
                                    @focus="$event.target.select()"
                                    @input="alert = false"
                                    outlined
                                    filled
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="2" md="2">
                                  <v-text-field
                                    outlined
                                    filled
                                    autocomplete="off"
                                    :ref="'qte' + index"
                                    class="inputPrice"
                                    type="number"
                                    dense
                                    v-model.number="article.qte"
                                    :label="
                                      'Quantité ' +
                                      (article.unit
                                        ? '(' + article.unit + ')'
                                        : '')
                                    "
                                    :rules="[
                                      (v) => !!v || 'Quantité obligatoire',
                                      (v) => v > 0 || 'Valeur incorrecte ',
                                      (v) =>
                                        v >= article.minvalue ||
                                        article.minvalue == null ||
                                        'Min ' + article.minvalue,
                                      (v) =>
                                        v <= article.maxvalue ||
                                        article.maxvalue == null ||
                                        'Max ' + article.maxvalue,
                                    ]"
                                    :key="kq"
                                    :readonly="readonly"
                                    :disabled="detail_load || add_disable"
                                    hide-spin-buttons
                                    :loading="add_disable"
                                    @focus="$event.target.select()"
                                    @keydown.enter="save"
                                    @input="alert = false"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  :sm="code_produit == '1' ? 5 : 7"
                                  :md="code_produit == '1' ? 5 : 7"
                                >
                                  <v-text-field
                                    outlined
                                    filled
                                    autocomplete="off"
                                    ref="comment"
                                    dense
                                    v-model="article.comment"
                                    :label="'Commentaire'"
                                    @focus="$event.target.select()"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'1'">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <listitems
                        :list="input_list"
                        :headers="inputheaders"
                        :title="'Rapport de Production'"
                        :master="true"
                        :add="false"
                        :del="false"
                        :key="frml2"
                        :ipg="5"
                        :exp_excel="false"
                        :print="false"
                        :Total="true"
                        :showexp="true"
                        @rowselect="InputSelect"
                        :exp_headers="outputheaders"
                        :exp_data="input_items"
                        :val_exp="'qte'"
                        :mes_exp="'Production'"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <listitems
                        :list="arret_list"
                        :headers="arretheaders"
                        :qCreate="Qarretcreate"
                        :qUpdate="Qarretupdate"
                        :qDelete="Qarretdelete"
                        :Get_suf="'FicheArret'"
                        :title="'Arrets'"
                        :master="false"
                        :add="fiche.statut_id == '18'"
                        :del="fiche.statut_id == '18'"
                        :Update="fiche.statut_id == '18'"
                        :showedit="true"
                        :print="false"
                        :Total="true"
                        :exp_excel="false"
                        :showstd="true"
                        :key="frml3"
                        :laoding="listload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
                <v-tab-item :value="'3'">
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <listitems
                        :list="dechet_list"
                        :headers="dechetheaders"
                        :qCreate="Qdechetcreate"
                        :qUpdate="Qdechetupdate"
                        :qDelete="Qdechetdelete"
                        :Get_suf="'FicheDechet'"
                        :title="'Dechets'"
                        :master="false"
                        :add="fiche.statut_id == '18'"
                        :del="fiche.statut_id == '18'"
                        :Update="fiche.statut_id == '18'"
                        :showedit="true"
                        :print="false"
                        :Total="true"
                        :exp_excel="false"
                        :showstd="true"
                        :key="frml4"
                        :laoding="listload"
                        :ipg="5"
                      >
                      </listitems>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-form>

    <confirmdialog ref="confirm" :is_motif="is_motif" @motif="get_motif" />
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
let time = new Date().toISOString().slice(11, 19);
import FICHE from "../graphql/Production/FICHE.gql";

import CREATE_FICHE from "../graphql/Production/CREATE_FICHE.gql";
import UPDATE_FICHE from "../graphql/Production/UPDATE_FICHE.gql";

import CREATE_FICHE_ARRET from "../graphql/Production/CREATE_FICHE_ARRET.gql";
import UPDATE_FICHE_ARRET from "../graphql/Production/UPDATE_FICHE_ARRET.gql";
import DELETE_FICHE_ARRET from "../graphql/Production/DELETE_FICHE_ARRET.gql";

import INSERT_FICHE_DETAIL from "../graphql/Production/INSERT_FICHE_DETAIL.gql";
import UPDATE_FICHE_DETAIL from "../graphql/Production/UPDATE_FICHE_DETAIL.gql";

import CREATE_FICHE_DECHET from "../graphql/Production/CREATE_FICHE_DECHET.gql";
import UPDATE_FICHE_DECHET from "../graphql/Production/UPDATE_FICHE_DECHET.gql";
import DELETE_FICHE_DECHET from "../graphql/Production/DELETE_FICHE_DECHET.gql";

import PRODUCT_LIST_INPUT from "../graphql/Product/PRODUCT_LIST_INPUT.gql";
import PRODUCTION_LIST from "../graphql/Production/PRODUCTION_LIST.gql";

import UPDATE_PRODUCT_LIST from "../graphql/Product/UPDATE_PRODUCT_LIST.gql";
import jsPDF from "jspdf";
//import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
export default {
  components: {
    confirmdialog: () => import("./ConfirmDialog.vue"),
    listitems: () => import("./ListItems.vue"),
    datepicker: () => import("./DatePicker.vue"),
  },
  name: "ficheprod",
  props: {
    item: Object,
    items: Array,
    open_bts: Array,
    shifts: Array,
    arrets: Array,
    dechets: Array,
    machines: Array,
    agents: Array,
    orders: Array,
    showForm: Boolean,
    readonly: Boolean,
    attributes: Array,
  },
  data: () => ({
    tabs: "0",
    dp: 1500,
    change: 0,
    glist: false,
    imp: false,
    title: "Liste Produit",
    listload: false,
    list: false,
    progress: false,
    kq: 3000,
    valid: true,
    valid1: true,
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    product: {},
    editedItem: {},
    articles_sel: [],
    articles_in: [],
    atelier: false,
    defaultItem: {
      id: -1,
      fiche_date: null,
      statut_name: "Brouillon",
      statut_id: "18",
      details: [],
    },
    fiche: {},
    intrant: {},
    qte: 0,
    tol: 0,
    bt: {},
    order: {},
    input: {},
    input_items: [],
    output: {},
    detail_load: false,
    add_disable: false,
    ag_redonly: false,
    inputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
      },
      {
        text: "Code",
        value: "code",
        hiden: true,
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        hiden: true,
        selected: true,
      },

      {
        text: "Ref.",
        value: "article_code",
        selected: false,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
      },
      {
        text: "Fournisseur",
        value: "tier_name",
        selected: false,
      },
      {
        text: "Quantité",
        value: "qte_consom",
        total: "qte_consom",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },

      {
        text: "Unité",
        value: "unit",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
    ],
    outputheaders: [
      {
        text: "No",
        value: "no",
        selected: true,
        totlib: true,
      },
      {
        text: "Code",
        value: "ref",
        hiden: true,
        selected: true,
      },
      {
        text: "Code",
        value: "ref",
        hiden: true,
        selected: true,
      },
      {
        text: "Produit",
        value: "label",
        selected: true,
        hiden: true,
      },

      {
        text: "Quantité",
        value: "qte",
        total: "qte",
        slot: "cur",
        align: "end",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        width: "100",
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Unité",
        value: "unit",
        selected: true,
        hiden: true,
      },
      {
        text: "Date",
        value: "created_at",
        selected: true,
      },
      {
        text: "OF",
        value: "no_order",
        selected: true,
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "",
        slot: "col_btn2",
        icon: "mdi-printer",
        selected: true,
      },
    ],
    arretheaders: [
      {
        text: "No",
        value: "no",
        totlib: "true",
        selected: true,
      },
      {
        text: "Type",
        value: "type_id",
        selected: true,
        slot: "simple-select",
        item_value: "id",
        item_text: "label",
        show_list: [],
      },
      {
        text: "Type Arret",
        value: "type_id",
        hiden: true,
        edit: true,
        type: "select",
        item_value: "id",
        item_text: "label",
        select_list: [],
        default: "",
        sm: 4,
        md: 4,
        rules: [(v) => !!v || "Type Arret obligatoire"],
      },
      {
        text: "Temps(min)",
        value: "temps",
        slot: "cur",
        align: "end",
        total: "temps",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "fiche_id",
        value: "fiche_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    dechetheaders: [
      {
        text: "No",
        value: "no",
        totlib: "true",
        selected: true,
      },
      {
        text: "Type",
        value: "type_id",
        selected: true,
        slot: "simple-select",
        item_value: "id",
        item_text: "label",
        show_list: [],
      },
      {
        text: "Type Dechet",
        value: "type_id",
        hiden: true,
        edit: true,
        type: "select",
        item_value: "id",
        item_text: "label",
        select_list: [],
        default: "",
        sm: 4,
        md: 4,
        rules: [(v) => !!v || "Type Dechet obligatoire"],
      },
      {
        text: "Quantité",
        value: "qte",
        slot: "cur",
        align: "end",
        total: "qte",
        selected: true,
        edit: true,
        type: "qte",
        dec: 2,
        sm: 3,
        md: 3,
        rules: [(v) => !!v || "Quantité obligatoire"],
      },
      {
        text: "Comment",
        value: "comment",
        selected: false,
        edit: true,
        type: "text",
        sm: 12,
        md: 12,
      },
      {
        text: "fiche_id",
        value: "fiche_id",
        default: "",
        edit: true,
        hiden: true,
        selected: false,
      },
    ],
    currentTab: 0,
    output_list: [],
    input_list: [],
    arret_list: [],
    dechet_list: [],
    machine_articles_livs: [],
    bom: [],
    frml: 600,
    frml2: 800,
    frml3: 900,
    frml4: 700,
    kl: 1900,
    kfo: 1000,
    input_detail: [],
    products_out: [],
    product_items: [],
    machines_list: [],
    articles: [],
    machine: {},
    product_in: {},
    selitem: {},
    alert: false,
    type_alert: "info",
    message: "",
    agent: {},
    num_seq: 1,
    is_motif: false,
  }),
  watch: {
    output_list: {
      handler() {
        // if (this.bt.order) {
        //   this.add_disable =
        //     (this.bt.order.links.length == 0 &&
        //       this.bt.order.bylist == "0" &&
        //       this.output_list.length > 0) ||
        //     (this.bt.order.links.length > 0 &&
        //       this.order.links.findIndex((elm) => elm.bylist == "1") == -1 &&
        //       this.output_list.length == this.bt.order.links.length);
        // }
        // if (this.products_out) {
        //   this.add_disable =
        //     this.products_out.findIndex((elm) => elm.bylist == "1") == -1 &&
        //     this.output_list.length == this.products_out.length;
        // }
      },
    },
  },
  computed: {
    qte_consom() {
      return this.input_list.reduce((a, b) => a + (b["qte_consom"] || 0), 0);
    },
    qFichedetail() {
      return UPDATE_FICHE_DETAIL;
    },

    Qarretcreate() {
      return CREATE_FICHE_ARRET;
    },
    Qarretupdate() {
      return UPDATE_FICHE_ARRET;
    },
    Qarretdelete() {
      return DELETE_FICHE_ARRET;
    },

    Qdechetcreate() {
      return CREATE_FICHE_DECHET;
    },
    Qdechetupdate() {
      return UPDATE_FICHE_DECHET;
    },
    Qdechetdelete() {
      return DELETE_FICHE_DECHET;
    },
    Qintrantupdate() {
      return UPDATE_FICHE_DETAIL;
    },
    code_produit() {
      return this.$store.state.options[0].code_produit;
    },
    gammes() {
      return this.order.gammes ? this.order.gammes : [];
    },
    amalgame() {
      let ok = false;
      if (
        (this.bt.tosell == "1" && this.bt.order.links.length > 0) ||
        this.machine.sp == "1"
      )
        ok = true;
      return ok;
    },

    bts_list() {
      return this.open_bts;
    },

    qte_lance() {
      let q = 0;
      if (this.bt.id)
        if (this.bt.qte)
          q = parseFloat(this.bt.qte.toFixed(this.order.decimal));

      return q;
    },
    lib_lance() {
      let l =
        "Qte BT: " +
        this.numberWithSpace(this.qte_lance.toFixed(this.order.decimal)) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return l;
    },

    qte_fab() {
      let f = 0;
      if (this.bt.id)
        if (this.bt.qte_fab)
          f = parseFloat(this.bt.qte_fab.toFixed(this.order.decimal));
      return f;
    },

    lib_fab() {
      let f =
        "Total " +
        (this.bt
          ? this.bt.product_statut
            ? this.bt.product_statut
            : "Fabriqué"
          : "") +
        ": " +
        this.numberWithSpace(this.qte_fab) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return f;
    },

    qte_fab_br() {
      let q = 0;
      if (this.bt.id)
        q = this.bt.qte_fab_br
          ? parseFloat(this.bt.qte_fab_br.toFixed(this.order.decimal))
          : 0;

      return q;
    },
    qte_rest() {
      let q = 0;
      if (this.bt.id) {
        if (this.qte_lance - this.qte_fab > 0)
          q = this.qte_lance - this.qte_fab;
        q = parseFloat(q.toFixed(this.order.decimal));
      }
      return q;
    },

    lib_rest() {
      let r =
        "Reste : " +
        this.numberWithSpace(this.qte_rest) +
        " " +
        (this.order.unit ? this.order.unit : "");
      return r;
    },
    attributs_list() {
      return this.attributes.filter((elm) => elm.type_attr == "colis");
    },
    bylist() {
      let l = "0";
      if (this.order.id) l = this.order.bylist;
      else l = "1";
      return l;
    },
    showDetail() {
      return this.showForm;
    },

    formTitle() {
      if (this.item)
        return this.item.id < 0
          ? "Nouvelle Fiche "
          : "Fiche N° " + this.fiche.nofiche;
      else return "";
    },
  },

  created() {},
  async mounted() {
    if (this.machines) this.machines_list = this.machines;
    if (this.item) {
      if (this.item.id > 0) {
        this.fiche = Object.assign({}, this.item);
        this.dp++;
        this.input_list = this.fiche.intrants;
        this.frml2++;
        this.arret_list = this.fiche.arrets;
        this.frml3++;
        this.dechet_list = this.fiche.dechets;
        this.frml4++;

        await this.machine_change();
        this.list_fiche();
        this.arretheaders[5].default = this.fiche.id;
        this.dechetheaders[5].default = this.fiche.id;
      } else {
        this.defaultItem.fiche_date = this.$store.state.today;
        this.fiche = Object.assign({}, this.defaultItem);
        let i = this.shifts.findIndex(
          (elm) => elm.time_from <= time && elm.time_to >= time
        );
        if (i >= 0) this.fiche.shift_id = this.shifts[i].id;

        if (this.orders.length == 1) {
          this.editedItem.of_id = this.orders[0].id;
          this.orders_change();
        }
        if (this.machines_list.length == 1) {
          this.fiche.machine_id = this.machines_list[0].machine_id;
          this.machine_change();
        }
      }

      if (
        this.$store.state.me.agent_id &&
        !this.$store.state.isadmin &&
        !this.$store.state.auth.includes("04023")
      ) {
        if (this.item.id < 0) {
          this.fiche.agent_id = this.$store.state.me.agent_id;
        }
        this.ag_redonly = true;
      }
    }
    this.arretheaders[1].show_list = this.arrets;
    this.arretheaders[2].select_list = this.arrets;
    this.dechetheaders[1].show_list = this.dechets;
    this.dechetheaders[2].select_list = this.dechets;
    this.outputheaders[1].hiden = this.code_produit == "1";
    this.outputheaders[2].hiden = this.code_produit == "0";
    this.inputheaders[1].hiden = this.code_produit == "1";
    this.inputheaders[2].hiden = this.code_produit == "0";
  },

  methods: {
    async refresh_product() {
      if (this.order.produit_id) {
        let v = {
          stock_id: 1,
          produit_id: this.order.produit_id,
          depot_id: this.machine.struct_id,
          machine_id: !this.atelier ? this.fiche.machine_id : null,
        };
        let r = await this.requette(PRODUCT_LIST_INPUT, v);
        if (r) {
          this.machine_articles_livs = r.product_list_input;
          if (this.machine_articles_livs)
            this.product_items = this.machine_articles_livs;
        }
      }
    },
    get_motif(m) {
      this.motif = m;
    },
    async consomed() {
      this.is_motif =
        this.articles_in[0].prc_rest >
        this.$store.state.options[0].seuil_justif_stock;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Considerer cet element comme etant consommé-Etês-vous sûr ?",
          { color: "orange darken-3" }
        )
      ) {
        let v = {
          productlist: {
            id: this.articles_in[0].id,
            consomed: 1,
            motif: this.motif,
          },
        };
        let r = await this.maj(UPDATE_PRODUCT_LIST, v);
        if (r) {
          this.frml++;
          let ind = this.product_items.findIndex(
            (elm) => elm.id == this.articles_in[0].id
          );
          if (ind >= 0) this.product_items.splice(ind, 1);
          this.articles_in.splice(0, 1);
        }
      }
      this.is_motif = false;
    },

    qte_change() {
      this.articles_sel
        .filter((elm) => elm.article_id != null)
        .forEach((element) => {
          element.qte = parseFloat(this.editedItem.qte);
        });
      this.kq++;
      this.alert = false;
    },
    current_change() {
      this.editedItem.ref = null;
      this.editedItem.qte = null;
      this.$refs.form.resetValidation();
    },
    onInputChange(value) {
      let i = this.product_items.findIndex((elm) => elm.ref == value);
      if (i >= 0) {
        this.articles_in.push(this.product_items[i]);
      }
    },
    customFilter(item, queryText) {
      let textlist = [];
      let l = [
        "product_code",
        "product_label",
        "article_code",
        "article_label",
        "label",
        "no_bt",
        "no_order",
        "nocmd",
        "tier_name",
        "ref",
        "code",
      ];

      l.forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    datefr(date) {
      var result = new Date(date);
      return result.toLocaleDateString("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },

    async orders_change() {
      let i = this.orders.findIndex((elm) => elm.id == this.editedItem.of_id);

      if (i >= 0) {
        this.order = this.orders[i];
        this.articles_sel = [];
        this.order.outputs.forEach((element) => {
          for (let index = 1; index <= element.ratio; index++) {
            this.articles_sel.push(element);
          }
        });

        this.refresh_product();
      }
    },
    async machine_change() {
      let i = this.machines.findIndex(
        (elm) => elm.machine_id == this.fiche.machine_id
      );

      if (i >= 0) {
        this.machine = this.machines[i];
        this.outputheaders[3].hiden = false;
        this.outputheaders[5].hiden = false;
        this.change++;
      }
    },
    verifie_double() {
      let ok = true;
      let code = "";
      if (this.amalgame) {
        for (
          let index = 0;
          index <
          this.articles_sel.filter((elm) => elm.article_id != null).length;
          index++
        ) {
          const element = this.articles_sel[index];
          let l = this.articles_sel.filter(
            (elm) => elm.id != element.id && elm.article_id != null
          );
          for (let index = 0; index < l.length; index++) {
            const elm = l[index];
            if (elm.ref.toLowerCase() == element.ref.toLowerCase()) {
              code = element.ref;
              ok = false;
              break;
            }
          }
          for (let index = 0; index < this.output_list.length; index++) {
            const elm = this.output_list[index];
            if (elm.ref.toLowerCase() == element.ref.toLowerCase()) {
              ok = false;
              code = element.ref;
              break;
            }
          }
          if (ok == false) break;
        }
      } else {
        if (this.editedItem.ref) {
          code = this.editedItem.ref;
          let i = this.output_list.findIndex(
            (elm) =>
              (elm.ref ? elm.ref.toLowerCase() : null) ==
              this.editedItem.ref.toLowerCase()
          );
          if (i >= 0) ok = false;
        }
      }
      if (ok == false) {
        this.message = "Erreur code " + code + " en double! verifier";
        this.type_alert = "error";
        this.alert = true;
        ok = false;
      }
      return ok;
    },
    async verif_intrants() {
      let ok = true;
      this.parents = [];
      let qte = 0;
      let rest = 0;
      let num = null;
      //verifier selection
      if (this.articles_in.length == 0) {
        this.message = "Veuillez selectionner l'intrant " + this.order.code;
        this.type_alert = "error";
        this.alert = true;
        ok = false;
      } else
        rest = this.articles_in.reduce((a, b) => a + (b["qte_rest"] || 0), 0);
      if (ok) {
        if (this.amalgame) {
          for (
            let index = 0;
            index <
            this.articles_sel.filter((elm) => elm.article_id != null).length;
            index++
          ) {
            const element = this.articles_sel[index];
            if (element.qte > qte) {
              qte = parseFloat(element.qte);
              num = index;
            }
          }
        } else qte = parseFloat(this.editedItem.qte);
        //verifier max
        let qte_rest = this.order.qte - this.order.qte_fab;
        if (this.qte_consom + qte > qte_rest && ok) {
          this.message =
            "Erreur quantité restante max  " +
            this.qte_rest +
            " -- de l'OF " +
            this.order.code;
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
        //verifier min
        if (qte > 0 && qte > rest && ok) {
          if (
            qte - rest <=
            this.articles_in.reduce((a, b) => a + (b["qte"] || 0)) *
              ((this.$store.state.options[0].taux_excee
                ? this.$store.state.options[0].taux_excee
                : 0) /
                100)
          ) {
            if (
              await this.$refs.confirm.open(
                "Attention",
                "Dépassement de quantité pour l'item N° " +
                  (num + 1) +
                  "-Etês-vous sûr ?",
                { color: "orange darken-3" }
              )
            ) {
              ok = true;
            } else ok = false;
          } else {
            this.message =
              "Erreur quantité pour l'item N° " + (num + 1) + " --Corriger";
            this.type_alert = "error";
            this.alert = true;
            ok = false;
          }
        }
        //verifier max
        if (this.articles_in.length > 0 && ok) {
          let m = 1;
          let q = 0;
          for (let index = 0; index < this.articles_in.length; index++) {
            const element = this.articles_in[index];
            q = q + element.qte_rest;
            if (q > qte) break;
            else m++;
          }
          if (this.articles_in.length > m && ok) {
            this.message =
              m +
              " " +
              this.order.colis_unit +
              " au maximum pour le produit " +
              this.order.code +
              " " +
              this.order.label;
            this.type_alert = "error";
            this.alert = true;
            ok = false;
          }
        }
        if (ok) {
          this.articles_in.sort(this.sortBy("qte_rest"));
          this.articles_in.forEach((element) => {
            let q = qte < element.qte_rest ? qte : element.qte_rest;
            this.parents.push({
              product_parent_id: element.id,
              qte: q,
              pmp: element.pmp,
              factor: element.factor,
              article_id: element.article_id,
              code: element.code,
            });
            qte = qte - q;
          });
        }
      }
      return ok;
    },
    async insert_output() {
      let list = [];

      for (
        let index = 0;
        index <
        this.articles_sel.filter((elm) => elm.article_id != null).length;
        index++
      ) {
        const element = this.articles_sel[index];
        let elm = {};
        elm.old_id = element.id;
        elm.ref = element.ref;
        elm.qte = element.qte;
        elm.comment = element.comment;
        elm.article_id = element.article_id;
        this.parents.forEach((p) => {
          elm.pu = (p.pmp * p.factor) / element.factor;
        });
        list.push(elm);
      }
      if (list.length > 0) {
        //
        let v = {
          create_uid: this.$store.state.me.id,
          of_id: this.order.id,
          fiche_id: this.fiche.id,
          machine_id: this.machine.machine_id,
          depot_id: this.machine.struct_id,
          list: list,
          parents: this.parents,
        };
        let r = await this.maj(INSERT_FICHE_DETAIL, v);
        return r;
      }
    },

    sortBy(prop) {
      return function (a, b) {
        return a[prop] > b[prop] ? 1 : -1;
      };
    },
    async add_output() {
      if (this.$refs.form.validate()) {
        let ok = true;
        this.add_disable = true;
        this.alert = false;
        // verifier double reference
        if (this.code_produit == "1") ok = this.verifie_double();
        // verifier les quantites necessaire de l'intrants
        if (ok) ok = await this.verif_intrants();
        let r;

        if (ok) {
          r = await this.insert_output();
          if (r) {
            if (r.ok) {
              this.editedItem.of_id = this.order.id;
              let d = JSON.parse(r.data.InsertFicheDetail);
              if (d) {
                if (d.length > 0) {
                  let r2 = await this.requette(FICHE, {
                    where: { column: "ID", value: this.fiche.id },
                  });
                  if (r2) {
                    this.fiche = r2.allfiches[0];
                    this.input_list = this.fiche.intrants;
                    this.frml2++;
                    this.arret_list = this.fiche.arrets;
                    this.frml3++;
                    this.dechet_list = this.fiche.dechets;
                    this.frml4++;
                    this.output_list = this.fiche.details;
                    this.listload = false;
                    this.frml++;
                  }
                  this.$store.dispatch("Changed", true);

                  let qte = 0;
                  //maj intrant
                  //  if (this.amalgame) {
                  for (
                    let index = 0;
                    index <
                    this.articles_sel.filter((elm) => elm.article_id != null)
                      .length;
                    index++
                  ) {
                    const element = this.articles_sel[index];
                    if (element.qte > qte) qte = element.qte;
                    element.qte = null;
                    element.ref = null;
                    element.comment = null;
                  }
                  //  } else qte = this.editedItem.qte;
                  this.editedItem.qte = null;
                  this.articles_in.sort(this.sortBy("qte_rest"));
                  this.articles_in.forEach((element) => {
                    let q = qte < element.qte_rest ? qte : element.qte_rest;
                    element.qte_rest = element.qte_rest - q;
                    element.prc_rest = (
                      (element.qte_rest / element.qte) *
                      100
                    ).toFixed(2);
                    qte = qte - q;
                  });
                  //DELETE if end BOTH
                  let ind = 0;
                  this.articles_in.forEach((element) => {
                    if (element.qte_rest == 0) this.articles_in.splice(ind, 1);
                    else ind++;
                  });
                  ind = 0;
                  this.product_items.forEach((element) => {
                    if (element.qte_rest == 0)
                      this.product_items.splice(ind, 1);
                    else ind++;
                  });
                  this.frml2++;
                  this.add_disable = false;

                  this.$refs.form.resetValidation();
                }
              }
            }
            if (!r.ok) {
              let s = r.error.graphQLErrors[0].debugMessage;
              if (
                s.search("1062") > 0 &&
                s.search("productions_fiches_details_index02") > 0
              ) {
                let s1 = s.indexOf("'", 0);
                let s2 = s.indexOf("'", s1 + 1);
                let k = s.substring(s1 + 1, s2);
                this.message = "Code " + k + " existe déja";
              } else this.message = "Erreur d'enregistrement inconnue ";
              this.type_alert = "error";
              this.alert = true;
              this.add_disable = false;
            }
          } else this.add_disable = false;
        } else this.add_disable = false;
      }
    },

    async delete_item(item) {
      let ok = true;
      this.alert = false;
      let v = {
        amg_id: item.amg_id,
      };
      let r = await this.requette(PRODUCTION_LIST, v);
      if (r) {
        let i = r.production_list.findIndex(
          (elm) => elm.qte_cons > 0 || elm.consomed == "1"
        );
        if (i >= 0) {
          this.message = "Production consommée--Suppression impossible";
          this.type_alert = "error";
          this.alert = true;
          ok = false;
        }
      }
      if (ok)
        if (
          await this.$refs.confirm.open(
            "Confirmation",
            "Etês-vous sûr de vouloir supprimer cet element?"
          )
        ) {
          let l = [];
          item.parents.forEach((element) => {
            l.push({
              production_parent_id: element.production_parent_id,
              product_parent_id: element.product_parent_id,
              article_id: element.article_id,
              qte: parseFloat(element.qte),
            });
          });
          v = {
            create_uid: this.$store.state.me.id,
            of_id: item.of_id,
            fiche_id: this.item ? this.item.id : null,
            depot_id: this.machine.struct_id,
            pfd_id: item.amg_id,
            parents: l,
          };
          r = await this.maj(INSERT_FICHE_DETAIL, v);
          if (r.ok) {
            let r2 = await this.requette(FICHE, {
              where: { column: "ID", value: this.fiche.id },
            });
            if (r2) {
              this.fiche = r2.allfiches[0];
              this.input_list = this.fiche.intrants;
              this.frml2++;
              this.arret_list = this.fiche.arrets;
              this.frml3++;
              this.dechet_list = this.fiche.dechets;
              this.frml4++;
              this.output_list = this.fiche.details;
              this.listload = false;
              this.frml++;
            }
            this.$store.dispatch("Changed", true);

            if (this.order.produit_id) {
              this.refresh_product();

              if (this.articles_in.length > 0)
                this.articles_in.forEach((element) => {
                  let j = this.product_items.findIndex(
                    (elm) => elm.id == element.id
                  );
                  if (j >= 0) element = this.product_items[j];
                });
            }
          } else {
            this.message = "Erreur de suppression";
            this.type_alert = "error";
            this.alert = true;
          }
        }
    },
    InputSelect(item) {
      this.intrant = item;
      this.input_items =
        item.product_childs.length > 0
          ? item.product_childs
          : item.production_childs;
    },
    OutputSelect(item) {
      this.output = item;
    },
    async print_ticket(item) {
      const doc = new jsPDF();

      // Ticket details
      const productCode = item.ref; // Example from the image
      const description = item.label; // Example from the image
      const material = item.fournis_name + "-" + item.ref_ext; // Example from the image
      const date = item.created_at; // Example from the image
      const length = item.qte + " " + item.unit; // Example from the image

      // Set font and size for the ticket
      doc.setFont("helvetica");
      doc.setFontSize(10);

      // Add QR Code to the left, below the date
      const qrCodeDataURL = await QRCode.toDataURL(productCode);
      doc.addImage(qrCodeDataURL, "PNG", 8, 9, 40, 40); // Adjust size and position as needed
      doc.line(46, 34, 110, 34);
      doc.setFontSize(9);
      doc.text(date, 12, 44, { angle: 90 }); // Rotate the date text 90 degrees

      // Add Product Code
      doc.setFontSize(8);
      doc.text(`${productCode}`, 46, 17);

      // Add Description
      doc.setFont(undefined, "bold");
      doc.text(`${description}`, 46, 22);

      // Add Material
      doc.setFont(undefined, "normal");
      doc.setFontSize(7);
      doc.text(`${material}`, 46, 27);
      doc.text(item.nofiche, 46, 32);
      doc.setFont(undefined, "bold");
      doc.setFontSize(22);

      // Add Length
      doc.text(`${length}`, 46, 42);

      // Print or Save PDF
      //doc.save("bobbins-ticket.pdf"); // Save as file
      //doc.autoPrint(); // Trigger print dialog
      window.open(doc.output("bloburl")); // Open in new tab for printing
    },
    OpenOutputForm(item) {
      this.editedItem = item;
    },

    numberWithSpace: function (x) {
      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ");
    },

    async list_fiche() {
      this.output_list = [];
      if (this.fiche.id >= 0) {
        this.listload = true;
        this.output_list = this.fiche.details;
        this.listload = false;
        this.frml++;
      }
    },

    async maj(query, v, refresh) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          if (refresh) {
            this.$store.dispatch("Changed", true);
            this.$emit("change");
          }
          r = { ok: true, data: data.data };
          this.progress = false;
        })
        .catch((error) => {
          r = { ok: false, error: error };
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async requette(query, v) {
      this.progress = true;

      let r = [];

      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    async close() {
      this.$emit("close", this.fiche);
    },
    async valider() {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir clotûrer cette fiche?"
        )
      ) {
        this.fiche.statut_id = 19;
        let v = {
          fiche: {
            id: this.fiche.id,
            statut_id: this.fiche.statut_id,
            write_uid: this.$store.state.me.id,
          },
        };

        await this.maj(UPDATE_FICHE, v, true);
      }
    },
    async save() {
      this.alert = false;
      if (this.$refs.form.validate()) {
        this.fiche.statut_id = 18;
        let v;
        if (this.fiche.id > 0) {
          v = {
            fiche: {
              id: this.fiche.id,
              gamme_id: this.order ? this.order.gamme_id : null,
              shift_id: this.fiche.shift_id,
              agent_id: this.fiche.agent_id,
              fiche_date: this.fiche.fiche_date,
              machine_id: this.fiche.machine_id,
              article_id: this.fiche.article_id,
              statut_id: this.fiche.statut_id,
              write_uid: this.$store.state.me.id,
            },
          };

          await this.maj(UPDATE_FICHE, v, true);
          this.$store.dispatch("Changed", true);
        } else {
          let v = {
            fiche: {
              gamme_id: this.order ? this.order.gamme_id : null,
              fiche_date: this.fiche.fiche_date,
              shift_id: this.fiche.shift_id,
              agent_id: this.fiche.agent_id,
              machine_id: this.fiche.machine_id,
              article_id: this.fiche.article_id,
              statut_id: this.fiche.statut_id,

              create_uid: this.$store.state.me.id,
              write_uid: this.$store.state.me.id,
            },
          };
          v = await this.maj(CREATE_FICHE, v);
          if (v.ok) {
            this.fiche.id = v.data.createFiche.id;
            this.fiche.nofiche = v.data.createFiche.nofiche;
            this.arretheaders[5].default = this.fiche.id;
            this.dechetheaders[5].default = this.fiche.id;

            this.$store.dispatch("Changed", true);
          }
        }
        this.$emit("refresh", this.fiche);
        this.change = 1;
      }
    },
  },
};
</script>

<style scoped>
.green-icon .v-icon {
  color: green;
}
.orange-icon .v-icon {
  color: orange;
}
.red-icon .v-icon {
  color: red;
}
.blue-icon .v-icon {
  color: rgb(21, 21, 197);
}
</style>
